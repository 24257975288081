const menu6=[
    { name: 'Carrot Cake', price: '$3.00', description: '' },
    { name: 'Vanilla Slice', price: '$2.50', description: '' },
    { name: 'Cheesecake', price: '$3.50', description: '' },
    { name: 'Donut', price: '$2.50', description: '' },
    { name: 'Muffin', price: '$4.00', description: '' },
    { name: 'Banana Bread', price: '$4.50', description: '' },
    { name: 'Raisin Toast', price: '$2.50', description: '' },
    { name: 'Pretzel', price: '$2.50', description: '' },

]
export default menu6;