const menu4=[
    { name: 'Iced Coffee', price: '$8.00', description: '' },
    { name: 'Iced Chocolate', price: '$8.00', description: '' },
    { name: 'Iced Mocha', price: '$8.00', description: '' },
    { name: 'Iced Chai', price: '$8.00', description: '' },
    { name: 'Iced Matcha', price: '$8.00', description: '' },
    { name: 'Iced Latte', price: '$7.00', description: '' },
    { name: 'Iced Long Black', price: '$7.00', description: '' },

]
export default menu4;