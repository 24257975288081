import customer1 from '../Images/customer1.png';
import customer2 from '../Images/customer2.png';
import customer3 from '../Images/customer3.png';


const customer=[
    {
        image: customer1,
        name:'Jane D.',
        description:"La Cuisine Cafe is my favorite spot in town! The coffee is exceptional, and the doughnuts are to die for."

    },
    {
        image: customer2,
        name:'Michael S.',
        description:"We hired La Cuisine Cafe for our wedding catering, and they exceeded our expectations. The food was incredible!"
    },
    {
        image: customer3,
        name:'Azura D.',
        description:"We hired La Cuisine Cafe for our wedding catering, and they exceeded our expectations. The food was incredible!"
    }
]
export default customer;