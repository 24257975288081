import sandwich from '../Images/sandwich.png';
const products=[
    {
        image:sandwich,
        item:"Macchito",
        price:"$3",
        description:"An espresso with a touch of steamed milk foam, delivering a rich flavor with a hint of sweetness."

    },
    {
        image:sandwich,
        item:"Macchito",
        price:"$3",
        description:"An espresso with a touch of steamed milk foam, delivering a rich flavor with a hint of sweetness."

    },
    {
        image:sandwich,
        item:"Macchito",
        price:"$3",
        description:"An espresso with a touch of steamed milk foam, delivering a rich flavor with a hint of sweetness."

    },
]

export default products; 